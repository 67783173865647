import { Dialog } from "@mui/material";
import { css } from "@emotion/react";
import { zhTW } from "date-fns/locale";
import { DateRangePicker, createStaticRanges } from "react-date-range";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  startOfYear,
  endOfYear,
  getYear,
  getMonth,
  format,
} from "date-fns";
import { forwardRef, useImperativeHandle, useState } from "react";
import ConfirmButton from "components/Button/ConfirmButton";
import CancelButton from "components/Button/CancelButton";

function DateRangeInput({ defaultValue = "" }, ref) {
  const [isOpen, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(defaultValue);

  const [rangeValue, setRangeValue] = useState({
    startDate: null,
    endDate: new Date(""),
    key: "selection",
  });

  const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
    startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  };

  const onSave = () => {
    const { startDate, endDate } = rangeValue;

    setInputValue(
      startDate === null && isNaN(endDate)
        ? ""
        : `${format(startDate, "yyyy-MM-dd")} ~ ${format(
            endDate,
            "yyyy-MM-dd"
          )}`
    );
    setOpen(false);
  };

  const defaultStaticRanges = createStaticRanges([
    {
      label: "今日此刻",
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "昨天",
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday,
      }),
    },
    {
      label: "過去 7 天",
      range: () => ({
        startDate: defineds.startOfLastSevenDay,
        endDate: defineds.endOfYesterday,
      }),
    },
    {
      label: "過去 30 天",
      range: () => ({
        startDate: defineds.startOfLastThirtyDay,
        endDate: defineds.endOfYesterday,
      }),
    },
    {
      label: "起始日當年",
      range: ({ range: { startDate } = {} } = {}) =>
        Boolean(startDate)
          ? {
              startDate: startOfYear(new Date(getYear(startDate), 0, 1)),
              endDate: endOfYear(new Date(getYear(startDate), 11, 31)),
            }
          : {
              startDate: startOfYear(new Date(getYear(new Date()), 0, 1)),
              endDate: endOfYear(new Date(getYear(new Date()), 11, 31)),
            },
      isSelected: ({ startDate, endDate } = {}) => {
        if (
          Boolean(startDate) &&
          Boolean(endDate) &&
          getYear(startDate) === getYear(endDate) &&
          isSameDay(startDate, startOfYear(startDate)) &&
          isSameDay(endDate, endOfYear(endDate))
        ) {
          return true;
        } else return false;
      },
    },
    {
      label: "起始日當月",
      range: ({ range: { startDate } = {} } = {}) => {
        return Boolean(startDate)
          ? {
              startDate: new Date(getYear(startDate), getMonth(startDate), 1),
              endDate: new Date(getYear(startDate), getMonth(startDate) + 1, 0),
            }
          : {
              startDate: new Date(getYear(new Date()), getMonth(new Date()), 1),
              endDate: new Date(getYear(new Date()), getMonth(new Date()), 0),
            };
      },
      isSelected: ({ startDate, endDate } = {}) => {
        if (
          Boolean(startDate) &&
          Boolean(endDate) &&
          getYear(startDate) === getYear(endDate) &&
          getMonth(startDate) === getMonth(endDate) &&
          isSameDay(startDate, startOfMonth(startDate)) &&
          isSameDay(endDate, endOfMonth(endDate))
        ) {
          return true;
        } else return false;
      },
    },
    {
      label: "清除",
      range: () => ({
        startDate: null,
        endDate: new Date(""),
      }),
      isSelected: () => false,
    },
  ]);

  const handleReset = () => {
    setInputValue("");

    setRangeValue({
      startDate: null,
      endDate: new Date(""),
      key: "selection",
    });
  };

  useImperativeHandle(ref, () => ({
    rangeValue,
    inputValue,
    onReset: handleReset,
  }));

  return (
    <>
      <div
        ref={ref}
        css={css(basicStyle)}
        onClick={() => {
          setOpen(true);
        }}
        className="date-range-input-wrapper"
      >
        {inputValue}
      </div>

      {isOpen && (
        <Dialog open css={css(dialogStyle)}>
          <DateRangePicker
            onChange={(item) => {
              console.log("item:", item);
              setRangeValue(item.selection);
            }}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[rangeValue]}
            direction="horizontal"
            preventSnapRefocus={true}
            calendarFocus="backwards"
            staticRanges={defaultStaticRanges}
            locale={zhTW}
            inputRanges={[]}
          />
          <div css={css(footerStyle)}>
            <CancelButton onClick={() => setOpen(false)} />
            <ConfirmButton onClick={onSave} />
          </div>
        </Dialog>
      )}
    </>
  );
}

export default forwardRef(DateRangeInput);

const footerStyle = css`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-right: 1rem;
    margin-bottom: 1rem;
    height: 28px;
  }
`;

const basicStyle = css`
  height: 36px;
  padding-right: 8px;
  border: 1px solid darkgray;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  cursor: pointer;
`;

const dialogStyle = css`
  .MuiPaper-root {
    max-width: 100%;
  }
  div.rdrDefinedRangesWrapper {
    width: 120px;
    min-width: 120px;
  }
  div.rdrInputRange {
    width: 100px;
  }
`;
