export const isEmptyText = (text) =>
  typeof text === "string" && text.trim().length > 0 ? false : true;

export const validateEmptyText = (text) =>
  isEmptyText(text) ? "此欄位為必填欄位" : "";

export const isPositiveNumber = (number) =>
  typeof number === "number" && number > 0 ? true : false;

export const validatePositiveNumber = (number) =>
  isPositiveNumber(number) ? "" : "請輸入大於 0 的數字";

// 儲存商品檢查
export const validateProductSave = (data = {}, setErrorMessage = () => {}) => {
  let errorObj = {};
  let hasError = false;
  const hasSpecLevel = data.specLevel.length > 0;

  if (hasSpecLevel) {
    errorObj = {
      productName: validateEmptyText(data.productName),
      specLevel: data.specLevel.map(({ level_name, spec_options = [] }) => ({
        level_name: validateEmptyText(level_name),
        spec_options: spec_options.map(({ spec_name }) => ({
          spec_name: validateEmptyText(spec_name),
        })),
      })),
    };
    errorObj.specLevel.forEach(({ level_name, spec_options = [] }) => {
      if (hasError) return;

      if (Boolean(level_name)) hasError = true;
      if (
        spec_options.some((item) =>
          Object.values(item).some((err) => Boolean(err))
        )
      )
        hasError = true;
    });
  } else {
    errorObj = {
      productName: validateEmptyText(data.productName),
    };
    hasError = Object.values(errorObj).some((k) => Boolean(k));
  }
  setErrorMessage(() => errorObj);

  // 返回是否通過
  return !hasError;
};
