import { css } from "@emotion/react";
import CancelButton from "components/Button/CancelButton";
import DeleteButton from "components/Button/DeleteButton";
import EditButton from "components/Button/EditButton";
import SaveButton from "components/Button/SaveButton";
import TableInput from "components/Input/TableInput";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TdComponent from "../TdComponent";

export default function withCategoryTableContainer(WrapperComponent) {
  return ({
    tableData = [],
    isDrag,
    setTableData = () => {},
    onDeleteButtonClick = () => {},
    onEditButtonClick = () => {},
    onInputValueChange = () => {},
    onInputSave = () => {},
    onInputCancel = () => {},
  }) => {
    const headerConfig = [
      {
        key: "category_name",
        label: "分類名稱",
        cusStyle: css`
          min-width: 100px;
          text-align: left;
          margin: 8px;
        `,
      },
      {
        key: "product_count",
        label: "商品數",
        cusStyle: css`
          min-width: 120px;
          width: 120px;
        `,
      },
      {
        key: "action",
        label: "操作",
        cusStyle: css`
          min-width: 180px;
          width: 180px;
        `,
      },
    ];

    const bodyConfig = [
      {
        bodyComp: ({ category_id, category_name, isEditting }) =>
          isEditting ? (
            <TableInput
              key={`${category_id}-${category_name}`}
              value={category_name}
              onChange={(value) => {
                onInputValueChange(category_id, value);
              }}
            />
          ) : (
            <TdComponent key={`${category_id}-${category_name}`}>
              {category_name}
            </TdComponent>
          ),
      },
      {
        bodyComp: ({ product_count }, idx) => (
          <td key={idx} style={{ textAlign: "center" }}>
            {product_count}
          </td>
        ),
      },
      {
        bodyComp: (record) => (
          <td
            style={{ textAlign: "center" }}
            css={css(actionStyle)}
            key={`${record.category_id}-action`}
          >
            {isDrag ? (
              "請拖曳來調整排序"
            ) : record?.category_id === 1 ? null : record.isEditting ? (
              <>
                <CancelButton
                  onClick={() => {
                    onInputCancel(record.category_id);
                  }}
                />
                <SaveButton
                  onClick={() => {
                    onInputSave(record.category_id);
                  }}
                  variant="contained"
                />
              </>
            ) : (
              <>
                <EditButton
                  onClick={() => {
                    onEditButtonClick(record);
                  }}
                />
                <DeleteButton
                  label="刪除"
                  onClick={() => {
                    onDeleteButtonClick(record);
                  }}
                />
              </>
            )}
          </td>
        ),
      },
    ];

    return (
      <DndProvider backend={HTML5Backend}>
        <WrapperComponent
          setTableData={setTableData}
          headerConfig={headerConfig}
          bodyConfig={bodyConfig}
          data={tableData}
          minWidth={1080}
        />
      </DndProvider>
    );
  };
}

const actionStyle = css`
  & button:first-of-type {
    margin-right: 4px;
  }

  & button:last-child {
    margin-left: 4px;
  }
`;
