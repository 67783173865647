import { css } from "@emotion/react";
import FiledInput from "components/Input/FieldInput";
import Title from "components/Typography/Title";

export default function NumberRangeInput({
  label,
  min = "",
  setMin = () => {},
  max = "",
  setMax = () => {},
}) {
  const handleChange = (key, e) => {
    const {
      target: { value },
    } = e;

    if (value !== "" && Number(value) < 0) return;

    const result = value === "" ? "" : Number(value);

    switch (key) {
      case "min":
        setMin(result);
        break;
      case "max":
        setMax(result);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {label && <Title cusStyle={titleStyle}>{label}</Title>}
      <div
        css={css(minStockCountWrapperStyle)}
        className="min-number-input-wrapper"
      >
        <FiledInput
          type="number"
          placeholder={"請輸入"}
          value={min}
          onChange={(e) => handleChange("min", e)}
        />
      </div>
      -
      <div
        css={css(maxStockCountWrapperStyle)}
        className="max-number-input-wrapper"
      >
        <FiledInput
          type="number"
          placeholder={"請輸入"}
          value={max}
          onChange={(e) => handleChange("max", e)}
        />
      </div>
    </>
  );
}

const minStockCountWrapperStyle = css`
  width: 100%;
  margin-right: 1rem;
`;

const maxStockCountWrapperStyle = css`
  width: 100%;
  margin-left: 1rem;
`;

const titleStyle = css`
  min-width: 58px;
  padding-right: 16px;
`;
