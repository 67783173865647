import { POST, fetches, generateFetchOpts } from "./util";

// 商品表現 - 取得列表
export const getProductPerformanceListApi = async ({
  page,
  perPage,
  payload,
}) => {
  return fetches(
    `/api/datacenter/product_performance.php`,
    generateFetchOpts({
      method: POST,
      body: { ...payload, page, size: perPage },
    })
  );
};
