import { css } from "@emotion/react";
import { Dialog } from "@mui/material";
import CancelButton from "components/Button/CancelButton";
import ConfirmButton from "components/Button/ConfirmButton";
import BigTitle from "components/Typography/BigTitle";
import { FONT_COLOR } from "config/Color";
import { flexCenter } from "style/flex";

export default function DeleteCategoryDialog({
  isOpen = true,
  data = {},
  onConfirm = () => {},
  onCancel = () => {},
}) {
  return (
    <Dialog css={css(custStyle)} open={isOpen}>
      <div css={css([flexCenter, titleWrapperStyle])}>
        <BigTitle>刪除提醒</BigTitle>
      </div>
      <div css={css([flexCenter, contentWrapperstyle])}>
        <BigTitle>如果該分類下有商品，會被歸類到"未分類"</BigTitle>
      </div>
      <div css={css([flexCenter, actionWrapperStyle])}>
        <CancelButton variant="contained" onClick={onCancel} />
        <ConfirmButton onClick={() => onConfirm(data)} />
      </div>
    </Dialog>
  );
}

const actionWrapperStyle = css`
  button {
    margin: 0 5px;
  }
`;

const custStyle = css`
  .MuiPaper-root {
    width: 400px;
    padding: 16px 24px;
  }
`;

const titleWrapperStyle = css`
  border-bottom: 1px solid ${FONT_COLOR.ONE};
  padding: 8px 0;
`;

const contentWrapperstyle = css`
  padding: 24px 0px;
`;
