import { ReactComponent as ProductManagementIcon } from "../assets/icon/product-management.svg";
import { ReactComponent as StockIcon } from "../assets/icon/stock.svg";
import { ReactComponent as DataMonitorIcon } from "../assets/icon/data-monitor.svg";

export const SIDEBAR_WIDTH = 200;

export const SIDEBAR_LIST = [
  {
    label: "商品管理",
    Icon: ProductManagementIcon,
    isExtended: true,
    extend: [
      {
        label: "我的商品",
        link: "/product-manage/my-product",
      },
      {
        label: "新增商品",
        link: "/product-manage/create-product",
      },{
        label: "商品分類",
        link: "/product-manage/product-category",
      },
    ],
  },
  {
    label: "庫存管理",
    Icon: StockIcon,
    link: "/stock-manage",
  },
  {
    label: "數據中心",
    Icon: DataMonitorIcon,
    isExtended: true,
    extend: [
      {
        label: "商品表現",
        link: "/data-center/product-performance",
      },
    ],
  },
];
