import { useMemo } from "react";
import { css } from "@emotion/react";
import TdComponent from "components/Table/TdComponent";
import { errorColorStyle } from "style/common";

// 商品表現 Table
export default function withProductPerformanceTableContainer(WrapperComponent) {
  return ({
    tableData = [],
    totalPage,
    perPage,
    page,
    onPerPageChange = () => {},
    onPageChange = () => {},
  }) => {
    // 取得每個商品的列述 Ex. { 商品名稱1 : 4, 商品名稱2 : 2 }
    const productNameCounts = useMemo(
      () =>
        tableData
          .map(({ product_name }) => product_name)
          .reduce((accumulator, currentItem) => {
            // 如果當前商品名稱已經存在於累加器中，則將其數量加1，否則設置數量為1
            accumulator[currentItem] = (accumulator[currentItem] || 0) + 1;
            return accumulator;
          }, {}),
      [tableData]
    );

    const headerConfig = [
      {
        key: "categroy_name",
        label: "商品分類",
        cusStyle: css`
          min-width: 100px;
        `,
      },
      {
        key: "product_name",
        label: "商品名稱",
        cusStyle: css`
          min-width: 300px;
        `,
      },
      {
        key: "specify",
        label: "商品規格",
        cusStyle: css`
          min-width: 250px;
        `,
      },
      {
        key: "period_in_qty",
        label: "期間入庫數",
        cusStyle: css`
          min-width: 120px;
        `,
      },
      {
        key: "stock_qty",
        label: "當前庫存",
        cusStyle: css`
          min-width: 120px;
        `,
      },
      {
        key: "period_defective_qty",
        label: "期間瑕疵數",
        cusStyle: css`
          min-width: 120px;
        `,
      },
    ];

    const bodyConfig = [
      {
        bodyComp: ({ product_name, categroy_name }, { rowIndex, tdIndex }) => {
          const prevRecord = rowIndex === 0 ? {} : tableData[rowIndex - 1];
          const mergetCounts = productNameCounts[product_name];
          const isFirstRow =
            rowIndex === 0 ? true : prevRecord.product_name !== product_name;
          const categroyName = Boolean(categroy_name)
            ? categroy_name
            : "未分類";

          return mergetCounts > 1 && isFirstRow ? (
            <TdComponent
              key={`td-${rowIndex}-${tdIndex}`}
              rowSpan={mergetCounts}
              align="center"
            >
              {categroyName}
            </TdComponent>
          ) : mergetCounts > 1 && !isFirstRow ? null : (
            <TdComponent key={`td-${rowIndex}-${tdIndex}`} align="center">
              {categroyName}
            </TdComponent>
          );
        },
      },
      {
        bodyComp: ({ product_name }, { rowIndex, tdIndex }) => {
          const prevRecord = rowIndex === 0 ? {} : tableData[rowIndex - 1];
          const mergetCounts = productNameCounts[product_name];
          const isFirstRow =
            rowIndex === 0 ? true : prevRecord.product_name !== product_name;

          return mergetCounts > 1 && isFirstRow ? (
            <TdComponent
              key={`td-${rowIndex}-${tdIndex}`}
              rowSpan={mergetCounts}
            >
              {product_name}
            </TdComponent>
          ) : mergetCounts > 1 && !isFirstRow ? null : (
            <TdComponent key={`td-${rowIndex}-${tdIndex}`}>
              {product_name}
            </TdComponent>
          );
        },
      },
      {
        bodyComp: (res, { rowIndex, tdIndex }) => {
          const { spec_name } = res;
          const [optionOne, optionTwo] = spec_name.split(",");

          return (
            <TdComponent key={`td-${rowIndex}-${tdIndex}`}>
              {Boolean(optionTwo)
                ? `${optionOne},${optionTwo}`
                : Boolean(optionOne)
                ? optionOne
                : "單一規格"}
            </TdComponent>
          );
        },
      },
      {
        bodyComp: ({ stock_qty, is_low_stock }, { rowIndex, tdIndex }) => (
          <TdComponent key={`td-${rowIndex}-${tdIndex}`} align="right">
            {Number(stock_qty).toLocaleString()}
            <br />
            {is_low_stock && <span css={css(errorColorStyle)}>低庫存</span>}
          </TdComponent>
        ),
      },
      {
        bodyComp: ({ period_in_qty }, { rowIndex, tdIndex }) => {
          return (
            <TdComponent key={`td-${rowIndex}-${tdIndex}`} align="right">
              {Number(period_in_qty).toLocaleString()}
            </TdComponent>
          );
        },
      },
      {
        bodyComp: ({ period_defective_qty }, { rowIndex, tdIndex }) => (
          <TdComponent key={`td-${rowIndex}-${tdIndex}`} align="right">
            {Number(period_defective_qty).toLocaleString()}
          </TdComponent>
        ),
      },
    ];

    return (
      <>
        <WrapperComponent
          headerConfig={headerConfig}
          bodyConfig={bodyConfig}
          data={tableData}
          minWidth={1035}
          isShowPagination
          totalPage={totalPage}
          perPage={perPage}
          page={page}
          onPerPageChange={onPerPageChange}
          onPageChange={onPageChange}
        />
      </>
    );
  };
}
