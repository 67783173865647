import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { css } from "@emotion/react";
import Title from "components/Typography/Title";

export default function DynamicAutoCompeleInput({
  titleStyle,
  inputStyle,
  label,
  list = [],
  value,
  setValue = () => {},
  payload = {},
  setPayload = () => {},
  placeholder,
}) {
  const [inputValue, setInputValue] = useState("");

  return (
    <>
      <Title cusStyle={titleStyle}>{label}</Title>
      <Autocomplete
        value={value}
        onChange={(_event, newValue) => {
          setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(_event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        css={css([autocompleteStyle, inputStyle])}
        options={list}
        noOptionsText="無匹配資料"
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        getOptionLabel={(options) => options.label || ""}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} />
        )}
      />
    </>
  );
}

const autocompleteStyle = css`
  width: 100%;
  max-width: 400px;
  min-width: 200px;
  .MuiInputBase-root {
    height: 36px;
  }
  input.MuiInputBase-input {
    padding: 0 !important;
  }
`;
