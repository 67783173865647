import DateRangeInput from "components/Input/DateRangeInput";
import Paper from "components/Common/Paper";
import Title from "components/Typography/Title";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { flexHorizontalCenter } from "style/flex";
import { css } from "@emotion/react";
import ContentWrpper from "components/Layout/ContentWrpper";
import NumberRangeInput from "components/Input/NumberRangeInput";
import { useEffect, useMemo, useRef, useState } from "react";
import LabelInput from "components/Input/LabelInput";
import { FONT_COLOR } from "config/Color";
import NavigateBar from "components/Layout/NavigateBar";
import { useDispatch } from "react-redux";
import { setErrorToast } from "feature/redux/slice/toast";
import { format } from "date-fns";
import { getProductPerformanceListApi } from "api/performance";
import { isEmptyText } from "core/validate";
import withProductPerformanceTableContainer from "components/Table/Hoc/withProductPerformanceTableContainer";
import Table from "components/Table/Table";
import SearchButton from "components/Button/SearchButton";
import ResetButton from "components/Button/ResetButton";
import AutoCompeleInput from "components/Input/AutoCompeleInput";
import { getCategoryListApi } from "api/category";

const FunctionTable = withProductPerformanceTableContainer(Table);

// 商品表現
export default function ProductPerformance() {
  const rangeRef = useRef(null);
  const dispatch = useDispatch();
  const [payload, setPayload] = useState({});
  const [searchQs, setSearchQs] = useState({});
  const [curPerPage, setCurPerPage] = useState(25);
  const [curPage, setCurPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryList, setCategoryList] = useState([]);

  const handleReset = async () => {
    setCurPage(1);
    setCurPerPage(25);
    setPayload({});
    setSearchQs({});

    if (Boolean(rangeRef?.current?.onReset)) rangeRef?.current?.onReset();

    await getProductPerformanceList({
      page: 1,
      perPage: 25,
    });
  };

  const handleSearch = async ({
    page = curPage,
    perPage = curPerPage,
    useLastPayload,
  }) => {
    setCurPage(page);
    setCurPerPage(perPage);
    let payloadObj = useLastPayload ? searchQs : {};

    if (!useLastPayload) {
      const minCountNum = parseFloat(payload?.stock_min);
      const maxCountNum = parseFloat(payload?.stock_max);
      const minCount = minCountNum > maxCountNum ? maxCountNum : minCountNum;
      const maxCount = minCountNum > maxCountNum ? minCountNum : maxCountNum;

      if (minCount > 0) payloadObj.stock_min = minCount;
      if (maxCount > 0) payloadObj.stock_max = maxCount;
      if (
        rangeRef?.current?.rangeValue?.startDate &&
        rangeRef?.current?.rangeValue?.endDate
      ) {
        payloadObj.start_date = format(
          rangeRef?.current?.rangeValue?.startDate,
          "yyyy-MM-dd"
        );
        payloadObj.end_date = format(
          rangeRef?.current?.rangeValue?.endDate,
          "yyyy-MM-dd"
        );
      }

      if (Boolean(selectedCategory)) {
        payloadObj.category_id = selectedCategory.id;
      }

      if (!isEmptyText(payload?.product_name)) {
        payloadObj.product_name = payload.product_name;
      }
    }

    await getProductPerformanceList({
      page,
      perPage,
      payload: payloadObj,
    });
  };

  const handleSetPayload = (key, value) => {
    setPayload((c) => ({ ...c, [key]: value }));
  };

  const handlePerPageChange = async (v) => {
    handleSearch({
      page: 1,
      perPage: v,
      useLastPayload: true,
    });
  };

  const handlePageChange = async (v) => {
    setCurPage(v);
    handleSearch({
      page: v,
      useLastPayload: true,
    });
  };

  const getProductPerformanceList = async ({
    payload = {},
    page = curPage,
    perPage = curPerPage,
  } = {}) => {
    try {
      // TODO：串接 取得商品表現列表 Api
      const { list = [], page_info: { total_records, total_pages } = {} } =
        await getProductPerformanceListApi({
          payload,
          page,
          perPage,
        });

      const data = [];

      list.forEach(
        ({ product_name, spec_level, categroy_name, spec_list = [] }) => {
          spec_list.forEach((v) => {
            data.push({ ...v, product_name, categroy_name, spec_level });
          });
        }
      );

      setTotalPage(total_pages);
      setTotalRecord(total_records);
      setTableData(data);
      setSearchQs(payload);
    } catch (error) {
      dispatch(setErrorToast(error?.message || "取得資料失敗"));
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        getProductPerformanceList();
        const { list = [] } = await getCategoryListApi();
        setCategoryList([
          ...list.map(({ category_name, category_id }) => ({
            label: category_name,
            id: category_id,
          })),
        ]);
      } catch (error) {
        dispatch(setErrorToast(error?.message || "取得資料失敗"));
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterText = useMemo(() => {
    const hasSearchParams = Object.keys(searchQs).length !== 0;
    const hasSearchName = Boolean(searchQs?.product_name);
    const hasSearchCount =
      searchQs.hasOwnProperty("stock_max") ||
      searchQs.hasOwnProperty("stock_min");
    const hasSearchRange =
      Boolean(searchQs?.start_date) && Boolean(searchQs?.end_date);
    const hasCategory = Boolean(searchQs?.category_id);
    const textArray = [];

    if (hasSearchRange) {
      textArray.push(
        ` 資料期間：${searchQs?.start_date} ~ ${searchQs?.end_date}`
      );
    }

    if (hasSearchCount) {
      textArray.push(
        `庫存數量：${
          searchQs.hasOwnProperty("stock_max") &&
          searchQs.hasOwnProperty("stock_min")
            ? `${searchQs.stock_min} - ${searchQs.stock_max}`
            : `${
                searchQs.hasOwnProperty("maxCount")
                  ? `庫存數量小於等於 ${searchQs.stock_max}`
                  : `庫存數量大於等於 ${searchQs.stock_min}`
              }`
        }`
      );
    }

    if (hasSearchName) textArray.push(`商品搜尋：${searchQs?.product_name}`);

    if (hasCategory) {
      const category = categoryList.filter(
        ({ id }) => id === searchQs?.category_id
      )[0].label;
      textArray.push(`商品分類：${category}`);
    }

    return hasSearchParams ? textArray.join(" | ") : "無";
  }, [searchQs, categoryList]);

  return (
    <Grid container>
      <Grid xs={12} sx={{ padding: "0" }}>
        <NavigateBar />
      </Grid>
      <ContentWrpper>
        <Grid xs={12}>
          <Paper>
            <Grid container spacing={1}>
              <Grid
                xs={12}
                css={css([flexHorizontalCenter, inputWrapperStyle])}
              >
                <LabelInput
                  label={"商品搜尋"}
                  onChange={(val) => handleSetPayload("product_name", val)}
                  value={payload?.product_name}
                  placeholder={"輸入商品名稱、規格來搜尋"}
                  maxLength={200}
                />
              </Grid>
              <Grid
                xs={12}
                css={css([flexHorizontalCenter, inputWrapperStyle])}
              >
                <AutoCompeleInput
                  titleStyle={inputTitleStyle}
                  inputStyle={autocompleteStyle}
                  list={categoryList}
                  placeholder={"不限"}
                  label={"商品分類"}
                  value={selectedCategory}
                  setValue={setSelectedCategory}
                />
              </Grid>
              <Grid
                xs={12}
                css={css([flexHorizontalCenter, inputWrapperStyle])}
              >
                <NumberRangeInput
                  label={"庫存數量"}
                  min={payload?.stock_min}
                  setMin={(val) => handleSetPayload("stock_min", val)}
                  max={payload?.stock_max}
                  setMax={(val) => handleSetPayload("stock_max", val)}
                />
              </Grid>
              <Grid
                xs={12}
                css={css([flexHorizontalCenter, inputWrapperStyle])}
              >
                <Title cusStyle={titleStyle}>資料期間</Title>
                <DateRangeInput
                  type="number"
                  placeholder={"請輸入"}
                  ref={rangeRef}
                />
              </Grid>
              <Grid
                xs={12}
                css={css([flexHorizontalCenter, actionWrapperStyle])}
              >
                <ResetButton onClick={handleReset} />
                <SearchButton
                  onClick={() => {
                    handleSearch({
                      page: 1,
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid xs={12} sx={{ paddingTop: "30px" }}>
          <Paper cusStyle={searchPaperStyle}>
            <div css={css([flexHorizontalCenter, searchHintStyle])}>
              <Title>搜尋商品總數：{totalRecord}</Title>
              <Title cusStyle={searchFilterStyle}>搜尋條件：{filterText}</Title>
            </div>
            <FunctionTable
              tableData={tableData}
              totalPage={totalPage}
              perPage={curPerPage}
              page={curPage}
              onPerPageChange={handlePerPageChange}
              onPageChange={handlePageChange}
            />
          </Paper>
        </Grid>
      </ContentWrpper>
    </Grid>
  );
}

const titleStyle = css`
  min-width: 58px;
  padding-right: 16px;
`;

const actionWrapperStyle = css`
  padding-top: 1rem;

  button {
    margin-right: 16px;
  }
`;

const searchFilterStyle = css`
  color: ${FONT_COLOR.ONE};
  padding-left: 24px;
`;

const searchPaperStyle = css`
  padding: 21px 16px;
`;

const inputWrapperStyle = css`
  div.label-input-wrapper {
    width: 100%;
    min-width: 300px;
    height: 40px;
  }

  div.date-range-input-wrapper {
    width: 100%;
    min-width: 280px;
    height: 40px;
  }

  div.min-number-input-wrapper,
  div.max-number-input-wrapper {
    width: 100%;
    min-width: 135px;
    height: 40px;
  }
`;

const searchHintStyle = css`
  padding-bottom: 10px;
`;

const inputTitleStyle = css`
  min-width: 58px;
  padding-right: 16px;
  text-align-last: justify;
`;
const autocompleteStyle = css`
  max-width: 100%;
`;
