import { POST, fetches, generateFetchOpts } from "./util";

/**
 * @name getCategoryListApi 取得分類列表
 */
export const getCategoryListApi = async (payload) => {
  return fetches(
    `/api/product/category/get_category_list.php`,
    generateFetchOpts({ method: POST, body: payload })
  );
};

/**
 * @name deleteCategoryApi 商品分類刪除
 */
export const deleteCategoryApi = async (category_id) => {
  return fetches(
    `/api/product/category/update_category.php`,
    generateFetchOpts({
      method: POST,
      body: {
        action: "delete",
        category_id,
      },
    })
  );
};

/**
 * @name createAndUpdateCategoryApi 商品分類新增、編輯
 */
export const createAndUpdateCategoryApi = async (body) => {
  return fetches(
    `/api/product/category/update_category.php`,
    generateFetchOpts({ method: POST, body: body })
  );
};

/**
 * @name categorySortApi 商品分類排序
 */
export const categorySortApi = async (categories) => {
  return fetches(
    `/api/product/category/update_category.php`,
    generateFetchOpts({
      method: POST,
      body: {
        action: "sort",
        categories,
      },
    })
  );
};
