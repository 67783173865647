import { POST, fetches, generateFetchOpts } from "./util";

/**
 * @name getProductListApi 商品列表、查詢
 */
export const getProductListApi = async (payload) => {
  return fetches(
    `/api/product/get_product_list.php`,
    generateFetchOpts({ method: POST, body: payload })
  );
};

/**
 * @name deleteProductApi 商品刪除
 */
export const deleteProductApi = async (product_id) => {
  return fetches(
    `/api/product/update_product.php`,
    generateFetchOpts({
      method: POST,
      body: {
        action: "delete",
        product_id,
      },
    })
  );
};

/**
 * @name createAndUpdateProductInfoApi 商品新增、編輯
 */
export const createAndUpdateProductInfoApi = async (body) => {
  return fetches(
    `/api/product/update_product.php`,
    generateFetchOpts({ method: POST, body: body })
  );
};
