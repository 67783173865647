export default function Test() {
  return (
    <iframe
      title="3ef6c47e-0eb6-4e87-be6b-d95f6ebe0738"
      scrolling="no"
      seamless="seamless"
      frameborder="0"
      allow="camera; clipboard-read; clipboard-write *"
      class="fever-plugin"
      src="https://dev.feversocial.com/promotions/3ef6c47e-0eb6-4e87-be6b-d95f6ebe0738/91579/join?test=QaMl4HIRD8LkSa5WcwG0aubRtb3Th3U0CBF4TPGt0xSaihWn0aBDXiMKL0ah%2BZqSBxUEUCvyVTOTyiPRBNYoYN7O01euwsV3SRH56xnHX93F0ivPRHBcdgVwMtTtQK1L&amp;test_code=a028e32641d76da7c5c200e8aabaed9f99ebfa21c6c701ce9278f95951b2e847&amp;test_expired=1734080540&amp;scenes=plugin&amp;parentUrl=https%3A%2F%2Fdev.feversocial.com%2FBryantDev%2F6414%3Ftest%3DQaMl4HIRD8LkSa5WcwG0aubRtb3Th3U0CBF4TPGt0xSaihWn0aBDXiMKL0ah%252BZqSBxUEUCvyVTOTyiPRBNYoYN7O01euwsV3SRH56xnHX93F0ivPRHBcdgVwMtTtQK1L%26test_code%3Da028e32641d76da7c5c200e8aabaed9f99ebfa21c6c701ce9278f95951b2e847%26test_expired%3D1734080540"
    ></iframe>
  );
}
