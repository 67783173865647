import Paper from "components/Common/Paper";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useEffect, useState } from "react";
import NavigateBar from "components/Layout/NavigateBar";
import ProductEditor from "components/Common/ProductEditor";
import { useNavigate } from "react-router-dom";
import ContentWrpper from "components/Layout/ContentWrpper";
import { validateProductSave } from "core/validate";
import { useDispatch } from "react-redux";
import { setErrorToast, setSuccessToast } from "feature/redux/slice/toast";
import { createAndUpdateProductInfoApi } from "api/product";
import withEditTableContainer from "components/Table/Hoc/withEditTableContainer";
import Table from "components/Table/Table";
import useGenerateTableData from "hook/useGenerateTableData";
import CancelButton from "components/Button/CancelButton";
import SaveButton from "components/Button/SaveButton";
import { getCategoryListApi } from "api/category";

const FunctionTable = withEditTableContainer(Table);

// 新增商品
export default function CreateProduct() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState({});
  const [productName, setProductName] = useState("");
  const [specLevel, setSpecLevel] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const { tableData, setTableData } = useGenerateTableData(specLevel, true);

  const handleSave = async () => {
    if (validateProductSave({ productName, specLevel }, setErrorMessage)) {
      try {
        const body = {
          action: "new",
          product_info: {
            product_name: productName,
            category_id: Boolean(selectedCategory) ? selectedCategory.id : 1,
            spec_level: specLevel.map(({ spec_options = [], level_name }) => ({
              level_name,
              spec_options: spec_options.map(({ spec_name }) => ({
                spec_name,
              })),
            })),
            spec_list: tableData.map(
              ({ stock_qty, price, safe_stock_qty }) => ({
                stock_qty: Number(stock_qty),
                price: Number(price),
                safe_stock_qty: Number(safe_stock_qty) || 0,
              })
            ),
          },
        };

        await createAndUpdateProductInfoApi(body);
        dispatch(setSuccessToast("儲存成功"));
        navigate("/product-manage/my-product");
      } catch (error) {
        dispatch(setErrorToast(error?.message || "儲存失敗"));
      }
    }
  };

  const handleCancel = () => {
    navigate("/product-manage/my-product");
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const { list = [] } = await getCategoryListApi();
        setCategoryList([
          ...list.map(({ category_name, category_id }) => ({
            label: category_name,
            id: category_id,
          })),
        ]);
      } catch (error) {
        dispatch(setErrorToast(error?.message || "取得資料失敗"));
      }
    };
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid container>
      <Grid xs={12} sx={{ padding: "0" }}>
        <NavigateBar>
          <CancelButton onClick={handleCancel} />
          <SaveButton variant="contained" onClick={handleSave} />
        </NavigateBar>
      </Grid>
      <ContentWrpper>
        <ProductEditor
          categoryList={categoryList}
          errorMessage={errorMessage}
          productName={productName}
          selectedCategory={selectedCategory}
          specLevel={specLevel}
          setSpecLevel={setSpecLevel}
          setProductName={setProductName}
          setSelectedCategory={setSelectedCategory}
          isCreate
        />
        <Grid xs={12} sx={{ paddingTop: "30px" }}>
          <Paper>
            <FunctionTable
              tableData={tableData}
              setTableData={setTableData}
              isCreate
            />
          </Paper>
        </Grid>
      </ContentWrpper>
    </Grid>
  );
}
