export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const DELETE = "DELETE";
const apiDomain = "https://ohspace.bizway.tw";

export async function fetches(path = "", option, payloadKey = "") {
  const apiPath = apiDomain + path;

  return fetch(apiPath, option)
    .then(normalizeResponse)
    .then((resp) => {
      const { httpStatus, payload = {} } = resp;

      if (httpStatus !== 200 || payload?.status !== "success")
        throw new Error(payload?.message);
      if (Boolean(payloadKey)) return payload[payloadKey];
      return resp.payload;
    });
}

const normalizeResponse = async (resp) => {
  return new Promise((reslove) => {
    reslove(resp.json());
  }).then((response) => {
    return { httpStatus: resp.status, payload: response };
  });
};

export function generateFetchOpts({
  method = "GET",
  headers = {},
  body = null,
  formData,
  ...rest
} = {}) {
  return {
    method,
    headers: new Headers({
      "Content-Type": "application/json",
      ...headers,
    }),
    ...(method !== "GET" ? { body: body && JSON.stringify(body) } : {}),
    ...rest,
  };
}
