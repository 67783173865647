import { css } from "@emotion/react";
import { BG_COLOR } from "config/Color";
import { HEADER_HEIGHT } from "config/Header";
import { SIDEBAR_WIDTH } from "config/Sidebar";

export default function Content({ children, isOpen }) {
  return <div css={css([wrapperStyle(isOpen)])}>{children}</div>;
}

const wrapperStyle = (isOpen) => css`
  height: calc(100% - ${HEADER_HEIGHT + 34}px);
  position: absolute;
  top: ${HEADER_HEIGHT}px;
  left: ${isOpen ? `${SIDEBAR_WIDTH}px` : 0};
  right: 0;
  background-color: ${BG_COLOR.THREE};
  padding: 16px;
`;
