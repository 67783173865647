import { createSlice } from "@reduxjs/toolkit";

export const TOAST_VARIANT_SUCCESS = "success";
export const TOAST_VARIANT_ERROR = "error";
export const TOAST_VARIANT_WARNING = "warning";
export const TOAST_VARIANT_INFO = "info";
export const TOAST_VARIANT_DEFAULT = "default";

export const toastSlice = createSlice({
  name: "toast",
  initialState: {
    message: "",
    variant: "default",
  },
  reducers: {
    setToast: (state, action) => {
      state.message = action.payload.message;
      state.variant = action.payload.variant;
    },
    setErrorToast: (state, action) => {
      state.message = action.payload;
      state.variant = TOAST_VARIANT_ERROR;
    },
    setSuccessToast: (state, action) => {
      state.message = action.payload;
      state.variant = TOAST_VARIANT_SUCCESS;
    },
    setWarningToast: (state, action) => {
      state.message = action.payload;
      state.variant = TOAST_VARIANT_WARNING;
    },
    setInfoToast: (state, action) => {
      state.message = action.payload;
      state.variant = TOAST_VARIANT_INFO;
    },
    setResetToast: (state) => {
      state.message = "";
      state.variant = TOAST_VARIANT_DEFAULT;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setToast, setErrorToast, setSuccessToast, setResetToast } =
  toastSlice.actions;

export default toastSlice.reducer;
