import { POST, fetches, generateFetchOpts } from "./util";

// 庫存管理頁面 - 取得庫存紀錄列表
export const getStockRecordListApi = async (body) => {
  return fetches(
    `/api/stock_manage/update_log.php`,
    generateFetchOpts({ method: POST, body })
  );
};

// 庫存管理頁面 - 復原紀錄
export const updateStockRecoveryRecordApi = async (historyUuid) => {
  return fetches(
    ``,
    generateFetchOpts({
      method: POST,
      body: {
        historyUuid,
      },
    })
  );
};

// 庫存管理頁面 - 更新紀錄
export const updateStockRecordApi = async (payload) => {
  return fetches(
    ``,
    generateFetchOpts({
      method: POST,
      body: payload,
    })
  );
};
