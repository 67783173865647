import { css } from "@emotion/react";
import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

const ItemType = "ROW";

export default function DragRow({ children, index, moveRow }) {
  const ref = useRef(null);
  const [, drag] = useDrag({
    type: ItemType,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    drop: (item) => {
      if (item.index !== index) {
        moveRow(item.index, index);
      }
    },
  });

  drag(drop(ref)); // 連接 `drag` 和 `drop` 到 DOM 元素

  return (
    <tr css={css(cusStyle)} ref={ref}>
      {children}
    </tr>
  );
}

const cusStyle = css`
  cursor: move;
`;
