import { css } from "@emotion/react";
import Paper from "components/Common/Paper";
import { flexHorizontalCenter } from "style/flex";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import FieldInput from "components/Input/FieldInput";
import { useMemo, useState } from "react";
import Title from "components/Typography/Title";
import SpecificationBlock from "components/Common/SpecificationBlock";
import DeleteSpecifyDialog from "components/Dialog/DeleteSpecifyDialog";
import { fullWidthStyle, paddingTop } from "style/common";
import AddButton from "components/Button/AddButton";
import AutoCompeleInput from "components/Input/AutoCompeleInput";
import { v4 as uuidv4 } from "uuid";

export default function ProductEditor({
  categoryList = [],
  productName = {},
  selectedCategory,
  specLevel = [],
  setProductName = () => {},
  setSelectedCategory = () => {},
  setSpecLevel = () => {},
  errorMessage,
  isCreate,
}) {
  const [isShowDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedLevelIndex, setSelectedLevelIndex] = useState(null);
  const [selectedSpecIndex, setSelectedSpecIndex] = useState(null);
  const [selectedLevelData, setSelectedLevelData] = useState({});
  const specLevelCounts = useMemo(() => specLevel.length, [specLevel]);

  // 新增規格
  const handleAddSpecification = () => {
    setSpecLevel((c) => [
      ...(c.length === 1 ? c : []),
      {
        level_name: "",
        level_id: `temp-${uuidv4()}`,
        spec_options: [
          {
            spec_name: "",
            spec_id: `temp-${uuidv4()}`,
          },
        ],
      },
    ]);
  };

  // 點擊刪除規格按鈕
  const deleteLevelClick = (leveIndex) => {
    if (isCreate) deleteLevel(leveIndex);
    else {
      setSelectedLevelIndex(leveIndex);
      setSelectedLevelData(specLevel[leveIndex]);
      setShowDeleteDialog(true);
    }
  };

  // 刪除規格
  const deleteLevel = (mainIndex) => {
    setSpecLevel((c) =>
      c.length === 1 ? [] : specLevel.filter((_, index) => index !== mainIndex)
    );
    if (!isCreate) setShowDeleteDialog(false);

    deleteReset();
  };

  // 點擊刪除選項按鈕
  const deleteSpecClick = (levelIndex, specIndex) => {
    if (isCreate) deleteSpec(levelIndex, specIndex);
    else {
      setSelectedLevelIndex(levelIndex);
      setSelectedSpecIndex(specIndex);
      setSelectedLevelData({
        ...specLevel[levelIndex],
        spec_options: [specLevel[levelIndex]["spec_options"][specIndex]],
      });
      setShowDeleteDialog(true);
    }
  };

  // 刪除選項
  const deleteSpec = (levelIndex, specIndex) => {
    setSpecLevel((c) => {
      return [
        ...c.map((item, index) =>
          index === levelIndex
            ? {
                ...item,
                spec_options: item.spec_options.filter(
                  (_, i) => i !== specIndex
                ),
              }
            : item
        ),
      ];
    });
    deleteReset();
  };

  // 新增選項
  const addSpecClick = (mainIndex) => {
    setSpecLevel((c) => {
      const { spec_options = [], ...rest } = c[mainIndex];

      return c.map((item, index) =>
        index === mainIndex
          ? {
              ...rest,
              spec_options: [
                ...spec_options,
                {
                  spec_name: "",
                  spec_id: `temp-${uuidv4()}`,
                },
              ],
            }
          : item
      );
    });
  };

  const speclLevelChange = ({ levelIndex, specIndex, value }) => {
    setSpecLevel((c) => {
      return c.map((item, index) => {
        if (index === levelIndex) {
          if (specIndex === undefined) {
            // optionIndex 為 undefined 代表是異動規格名稱
            return { ...item, ...{ level_name: value } };
          } else {
            // 有 optionIndex 代表是異動選項名稱
            return {
              ...item,
              spec_options: item["spec_options"].map(
                ({ spec_name, ...rest }, i) => ({
                  spec_name: i === specIndex ? value : spec_name,
                  ...rest,
                })
              ),
            };
          }
        } else return item;
      });
    });
  };

  const deleteReset = () => {
    setShowDeleteDialog(false);
    setSelectedLevelIndex(null);
    setSelectedSpecIndex(null);
    setSelectedLevelData({});
  };

  const productNameChange = (e) => {
    setProductName(e?.target?.value);
  };

  return (
    <Grid xs={12}>
      <Paper>
        <Grid container>
          <Grid xs={12} display={"flex"} css={css(titleWrapperStyle)}>
            <Title cusStyle={titleStyle}>商品名稱</Title>
            <FieldInput
              error={errorMessage?.productName}
              onChange={productNameChange}
              value={productName}
              placeholder={"輸入商品名稱"}
              maxLength={200}
              inputLength={productName?.length || 0}
            />
          </Grid>
          <Grid xs={12} display={"flex"} css={paddingTop(10)}>
            <AutoCompeleInput
              titleStyle={titleStyle}
              list={categoryList}
              placeholder={"未分類"}
              label={"商品分類"}
              value={selectedCategory}
              setValue={setSelectedCategory}
            />
          </Grid>
          {specLevel.length > 0 && (
            <Grid xs={12} display={"flex"} css={paddingTop(10)}>
              <Title cusStyle={titleStyle}>規格</Title>
              <div css={css(fullWidthStyle)}>
                {specLevel.map((item, index) => (
                  <SpecificationBlock
                    key={index}
                    errorMessage={errorMessage?.specLevel?.[index]}
                    index={index}
                    data={item}
                    onChange={speclLevelChange}
                    onDeleteLevelClick={deleteLevelClick}
                    onAddSpecClick={addSpecClick}
                    onDeleteSpecClick={deleteSpecClick}
                  />
                ))}
              </div>
            </Grid>
          )}
          {specLevelCounts < 2 && (
            <Grid
              xs={12}
              css={css([flexHorizontalCenter])}
              sx={{ paddingTop: "16px" }}
            >
              <AddButton onClick={handleAddSpecification} label={"新增規格"} />
            </Grid>
          )}
        </Grid>
      </Paper>

      <DeleteSpecifyDialog
        isOpen={isShowDeleteDialog}
        data={selectedLevelData}
        onConfirm={() => {
          if (selectedSpecIndex !== null) {
            deleteSpec(selectedLevelIndex, selectedSpecIndex);
          } else deleteLevel(selectedLevelIndex);
        }}
        onCancel={deleteReset}
      />
    </Grid>
  );
}

const titleStyle = css`
  min-width: 58px;
  padding-right: 16px;
  margin-top: 12px;
  text-align: end;
`;

const titleWrapperStyle = css`
  .field-input-wrapper {
    min-width: 300px;
  }
`;
