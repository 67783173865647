import Paper from "components/Common/Paper";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useEffect, useState } from "react";
import NavigateBar from "components/Layout/NavigateBar";
import ProductEditor from "components/Common/ProductEditor";
import { useNavigate, useParams } from "react-router-dom";
import ContentWrpper from "components/Layout/ContentWrpper";
import { validateProductSave } from "core/validate";
import { useDispatch } from "react-redux";
import { setErrorToast, setSuccessToast } from "feature/redux/slice/toast";
import withEditTableContainer from "components/Table/Hoc/withEditTableContainer";
import Table from "components/Table/Table";
import useGenerateTableData from "hook/useGenerateTableData";
import CancelButton from "components/Button/CancelButton";
import SaveButton from "components/Button/SaveButton";
import { createAndUpdateProductInfoApi, getProductListApi } from "api/product";
import { getCategoryListApi } from "api/category";
import { v4 as uuidv4 } from "uuid";

const FunctionTable = withEditTableContainer(Table);

// 編輯商品
export default function EditProduct() {
  const navigate = useNavigate();
  const { productId } = useParams();
  const dispatch = useDispatch();
  const [productName, setProductName] = useState("");
  const [specLevel, setSpecLevel] = useState([]);
  const [errorMessage, setErrorMessage] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const { tableData, setTableData } = useGenerateTableData(specLevel);

  const handleSave = async () => {
    if (validateProductSave({ productName, specLevel }, setErrorMessage)) {
      try {
        const body = {
          action: "update",
          product_id: productId,
          product_info: {
            product_name: productName,
            category_id: Boolean(selectedCategory) ? selectedCategory.id : 1,
            spec_level: specLevel.map(({ spec_options = [], level_name }) => ({
              level_name,
              spec_options: spec_options.map(({ spec_name }) => ({
                spec_name,
              })),
            })),
            spec_list: tableData.map(
              ({ stock_qty, price, safe_stock_qty }) => ({
                stock_qty: Number(stock_qty),
                price: Number(price),
                safe_stock_qty: Number(safe_stock_qty) || 0,
              })
            ),
          },
        };

        await createAndUpdateProductInfoApi(body);
        dispatch(setSuccessToast("儲存成功"));
        navigate("/product-manage/my-product");
      } catch (error) {
        dispatch(setErrorToast(error?.message || "儲存失敗"));
      }
    }
  };

  const handleCancel = () => {
    navigate("/product-manage/my-product");
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const [{ list: productList = [] }, { list: categorylist = [] }] =
          await Promise.all([
            getProductListApi({
              product_id: productId,
            }),
            getCategoryListApi(),
          ]);

        const categoryOptionList = [
          ...categorylist.map(({ category_name, category_id }) => ({
            label: category_name,
            id: category_id,
          })),
        ];
        setCategoryList(categoryOptionList);

        if (productList.length === 1) {
          const {
            product_name,
            category_id,
            spec_level,
            spec_list = [],
          } = productList[0];
          setSelectedCategory(
            categoryOptionList.filter(({ id }) => id === category_id)[0]
          );
          setProductName(product_name);
          const levelArray = Boolean(spec_level) ? spec_level.split(",") : [];
          const levelOneSpec = new Set([]);
          const levelTwoSpec = new Set([]);

          spec_list.forEach(({ spec_name }) => {
            const [spec_one, spec_two] = spec_name.split(",");
            levelOneSpec.add(spec_one);
            levelTwoSpec.add(spec_two);
          });

          let specLevel = [];
          const level_id_one = `temp-${uuidv4()}`;
          const level_id_two = `temp-${uuidv4()}`;

          if (levelArray.length !== 0) {
            specLevel = levelArray.map((levelName, index) => {
              const specGroup = Array.from(
                index === 0 ? levelOneSpec : index === 1 ? levelTwoSpec : []
              );

              return {
                level_id: index === 0 ? level_id_one : level_id_two,
                level_name: levelName,
                spec_options: specGroup.map((spec_name) => ({
                  spec_name,
                  spec_id: `temp-${uuidv4()}`,
                })),
              };
            });
          }
          const levelSpecInfo = specLevel.flatMap((item) =>
            item.spec_options.map((option) => ({
              level_id: item.level_id,
              level_name: item.level_name,
              spec_name: option.spec_name,
              spec_id: option.spec_id,
            }))
          );

          const tableData =
            spec_list.length > 0
              ? spec_list.map(
                  ({ stock_qty, spec_name, price, safe_stock_qty }) => {
                    const [spec_name_one, spec_name_two] = spec_name.split(",");

                    const level_one = levelSpecInfo.filter(
                      ({ spec_name }) => spec_name === spec_name_one
                    )[0];
                    const level_two = levelSpecInfo.filter(
                      ({ spec_name }) => spec_name === spec_name_two
                    )[0];

                    return {
                      price,
                      safe_stock_qty,
                      spec_name_one,
                      stock_qty,
                      ...(Boolean(level_one)
                        ? {
                            spec_name_one,
                            level_id_one: level_one.level_id,
                            level_name_one: level_one.level_name,
                            spec_id_one: level_one.spec_id,
                          }
                        : {}),
                      ...(Boolean(level_two)
                        ? {
                            spec_name_two,
                            level_id_two: level_two.level_id,
                            level_name_two: level_two.level_name,
                            spec_id_two: level_two.spec_id,
                          }
                        : {}),
                    };
                  }
                )
              : [];

          setTableData(tableData);
          setSpecLevel(specLevel);
        } else {
          dispatch(setErrorToast("查無此商品，請重新選擇"));
          navigate(`/product-manage/my-product`);
        }
      } catch (error) {
        console.log("error:", error);
        dispatch(setErrorToast(error?.message || "取得商品資料失敗"));
      }
    };
    if (productId) getData();
  }, [dispatch, productId, setTableData, navigate]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { list = [] } = await getCategoryListApi();

        const categoryList = [
          ...list.map(({ category_name, category_id }) => ({
            label: category_name,
            id: category_id,
          })),
        ];
        setCategoryList(categoryList);
      } catch (error) {
        dispatch(setErrorToast(error?.message || "取得資料失敗"));
      }
    };
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid xs={12} sx={{ padding: "0" }}>
        <NavigateBar specifyRoute={["商品管理", "編輯商品"]}>
          <CancelButton onClick={handleCancel} />
          <SaveButton variant="contained" onClick={handleSave} />
        </NavigateBar>
      </Grid>
      <ContentWrpper>
        <ProductEditor
          categoryList={categoryList}
          productName={productName}
          setProductName={setProductName}
          selectedCategory={selectedCategory}
          specLevel={specLevel}
          setSpecLevel={setSpecLevel}
          errorMessage={errorMessage}
          setSelectedCategory={setSelectedCategory}
        />
        <Grid xs={12} sx={{ paddingTop: "30px" }}>
          <Paper>
            <FunctionTable tableData={tableData} setTableData={setTableData} />
          </Paper>
        </Grid>
      </ContentWrpper>
    </Grid>
  );
}
