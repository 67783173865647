import React from "react";
import { SnackbarProvider, useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  TOAST_VARIANT_ERROR,
  TOAST_VARIANT_SUCCESS,
  TOAST_VARIANT_WARNING,
  setResetToast,
} from "feature/redux/slice/toast";
import { TOAST_COLOR } from "config/Color";

export default function ProviderToast({ children }) {
  return (
    <SnackbarProvider maxSnack={3}>
      <ToastTrigger />
      {children}
    </SnackbarProvider>
  );
}

const COLOR_MAPPING = {
  [TOAST_VARIANT_SUCCESS]: TOAST_COLOR.SUCCES,
  [TOAST_VARIANT_ERROR]: TOAST_COLOR.ERROR,
  [TOAST_VARIANT_WARNING]: TOAST_COLOR.WARNING,
};

function ToastTrigger() {
  const { enqueueSnackbar } = useSnackbar();
  const disptach = useDispatch();
  const { message, variant } = useSelector((s) => s.toast);

  useEffect(() => {
    if (message) {
      enqueueSnackbar(message, {
        variant,
        style: {
          backgroundColor: COLOR_MAPPING[variant],
          fontSize: "14px",
          fontWeight: 400,
        },
      });

      disptach(setResetToast());
    }
  }, [disptach, enqueueSnackbar, message, variant]);

  return <></>;
}
