import { css } from "@emotion/react";
import Paper from "components/Common/Paper";
import { flexHorizontalCenter } from "style/flex";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Title from "components/Typography/Title";
import NavigateBar from "components/Layout/NavigateBar";
import { FONT_COLOR } from "config/Color";
import ContentWrpper from "components/Layout/ContentWrpper";
import DeleteProductDialog from "components/Dialog/DeleteProductDialog";
import { useDispatch } from "react-redux";
import { setErrorToast, setSuccessToast } from "feature/redux/slice/toast";
import NumberRangeInput from "components/Input/NumberRangeInput";
import LabelInput from "components/Input/LabelInput";
import withMyProductTableContainer from "components/Table/Hoc/withMyProductTableContainer";
import Table from "components/Table/Table";
import SearchButton from "components/Button/SearchButton";
import ResetButton from "components/Button/ResetButton";
import AddButton from "components/Button/AddButton";
import { deleteProductApi, getProductListApi } from "api/product";
import AutoCompeleInput from "components/Input/AutoCompeleInput";
import { getCategoryListApi } from "api/category";

const DEFAULT_PER_PAGE = 25;

const FunctionTable = withMyProductTableContainer(Table);

// 我的商品
export default function MyProduct() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isShowDeleteDialog, setShowDeleteDialog] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [stockMin, setStockMin] = useState("");
  const [stockMax, setStockMax] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(DEFAULT_PER_PAGE);
  const [searchParams, setSearchParams] = useState({});
  const [deletedData, setDeleteData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [totalRecord, setTotalRecord] = useState();
  const [totalPage, setTotalPage] = useState(1);
  const [categoryList, setCategoryList] = useState([]);

  const handleDeleteButtonClick = (row) => {
    setShowDeleteDialog(true);
    setDeleteData(row);
    const { product_id } = row;
    const data = tableData.filter(
      ({ product_id: tableId }) => tableId === product_id
    )[0];
    const { spec_list = [], spec_level } = data;

    setDeleteData({
      ...row,
      spec_name: Boolean(spec_level)
        ? spec_list.map(({ spec_name }) => spec_name).join("、")
        : "單一規格",
    });
  };

  const handleEditButtonClick = ({ product_id }) => {
    navigate(`/product-manage/${product_id}/edit`);
  };

  const handleDeleteDialogCancel = () => {
    setShowDeleteDialog(false);
    setDeleteData({});
  };

  const handleDelete = async (data = {}) => {
    try {
      const { product_id } = data;
      await deleteProductApi(product_id);

      setShowDeleteDialog(false);
      dispatch(setSuccessToast("刪除成功"));
      handleSearch();
      setDeleteData({});
    } catch (error) {
      dispatch(setErrorToast(error?.message || "刪除失敗"));
    }
  };

  const handleReset = async () => {
    setKeyword("");
    setStockMin("");
    setStockMax("");
    setSelectedCategory("");

    await handleSearch({
      curPage: 1,
      curSize: DEFAULT_PER_PAGE,
      keyword: "",
      stock_min: "",
      stock_max: "",
      category_id: "",
    });
  };

  const handleSearch = async ({
    curPage = page,
    curSize = size,
    ...rest
  } = {}) => {
    try {
      setPage(curPage);
      setSize(curSize);

      const stockMinNum = parseFloat(stockMin);
      const stockMaxNum = parseFloat(stockMax);
      const min = stockMinNum > stockMaxNum ? stockMaxNum : stockMinNum;
      const max = stockMinNum > stockMaxNum ? stockMinNum : stockMaxNum;

      const originSearchPayload = {
        ...(keyword.length > 0 ? { keyword } : {}),
        ...(min > 0 ? { stock_min: min } : {}),
        ...(max > 0 ? { stock_max: max } : {}),
        ...(Boolean(selectedCategory)
          ? { category_id: selectedCategory.id }
          : {}),
        ...rest,
      };

      const searchPayload = Object.fromEntries(
        Object.entries(originSearchPayload).filter(
          ([_key, value]) =>
            value !== "" && value !== null && value !== undefined
        )
      );

      const payload = {
        page: curPage,
        size: curSize,
        ...searchPayload,
      };

      const { page_info: { total_records, total_pages } = {}, list = [] } =
        await getProductListApi(payload);

      setTableData(list);
      setTotalRecord(total_records);
      setTotalPage(total_pages);
      setSearchParams(searchPayload);
    } catch (error) {
      dispatch(setErrorToast(error?.message || "取得商品列表失敗"));
    }
  };

  const handlePerPageChange = (v) => {
    handleSearch({
      curPage: 1,
      curSize: v,
    });
  };

  const handlePageChange = (v) => {
    handleSearch({
      curPage: v,
    });
  };

  const filterText = useMemo(() => {
    const hasSearchParams = Object.keys(searchParams).length !== 0;
    const hasKeyword = Boolean(searchParams?.keyword);
    const hasMax =
      Boolean(searchParams?.stock_max) || searchParams?.stock_max === 0;
    const hasMin =
      Boolean(searchParams?.stock_min) || searchParams?.stock_min === 0;
    const hasCategory = Boolean(searchParams?.category_id);

    const textArray = [];

    if (Boolean(searchParams?.keyword)) {
      textArray.push(`商品搜尋：${searchParams.keyword} `);
    }

    if (hasMin || hasMax) {
      const min = searchParams.stock_min;
      const max = searchParams.stock_max;
      textArray.push(
        `${hasKeyword ? "|" : ""} 庫存數量：${
          hasMin && hasMax
            ? `${min} - ${max}`
            : `${
                hasMax ? `庫存數量小於等於 ${max}` : `庫存數量大於等於 ${min}`
              }`
        }`
      );
    }

    if (hasCategory) {
      const category = categoryList.filter(
        ({ id }) => id === searchParams?.category_id
      )[0].label;
      textArray.push(`商品分類：${category}`);
    }

    return hasSearchParams ? textArray.join(" | ") : "無";
  }, [searchParams, categoryList]);

  useEffect(() => {
    const getData = async () => {
      handleSearch();
      try {
        const { list = [] } = await getCategoryListApi();
        setCategoryList([
          ...list.map(({ category_name, category_id }) => ({
            label: category_name,
            id: category_id,
          })),
        ]);
      } catch (error) {
        dispatch(setErrorToast(error?.message || "取得資料失敗"));
      }
    };
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid xs={12} sx={{ padding: "0" }}>
        <NavigateBar>
          <AddButton
            variant="contained"
            label={"新增商品"}
            onClick={() => navigate("/product-manage/create-product")}
          />
        </NavigateBar>
      </Grid>
      <ContentWrpper>
        <Grid xs={12}>
          <Paper cusStyle={searchPaperStyle}>
            <Grid container spacing={{ xs: 2 }}>
              <Grid
                xs={12}
                md={6}
                css={css([flexHorizontalCenter, inputWrapperStyle])}
              >
                <LabelInput
                  label={"商品搜尋"}
                  onChange={setKeyword}
                  value={keyword}
                  placeholder={"輸入商品名稱、規格來搜尋"}
                  maxLength={100}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
                css={css([flexHorizontalCenter, inputWrapperStyle])}
              >
                <NumberRangeInput
                  label={"庫存數量"}
                  min={stockMin}
                  setMin={setStockMin}
                  max={stockMax}
                  setMax={setStockMax}
                />
              </Grid>
              <Grid
                xs={12}
                css={css([flexHorizontalCenter, actionWrapperStyle])}
              >
                <AutoCompeleInput
                  titleStyle={inputTitleStyle}
                  list={categoryList}
                  placeholder={"不限"}
                  label={"商品分類"}
                  value={selectedCategory}
                  setValue={setSelectedCategory}
                />
              </Grid>
              <Grid
                xs={12}
                css={css([flexHorizontalCenter, actionWrapperStyle])}
              >
                <ResetButton onClick={handleReset} />
                <SearchButton
                  onClick={() => {
                    handleSearch({ curPage: 1 });
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid xs={12} sx={{ paddingTop: "30px" }}>
          <Paper cusStyle={searchPaperStyle}>
            <div css={css([flexHorizontalCenter, titleWrapperStyle])}>
              <Title>搜尋商品總數：{totalRecord}</Title>
              <Title cusStyle={searchFilterStyle}>
                搜尋條件：
                {filterText}
              </Title>
            </div>
            <FunctionTable
              tableData={tableData}
              onDeleteButtonClick={handleDeleteButtonClick}
              onEditButtonClick={handleEditButtonClick}
              totalPage={totalPage}
              onPerPageChange={handlePerPageChange}
              onPageChange={handlePageChange}
              perPage={size}
              page={page}
            />
          </Paper>
        </Grid>
      </ContentWrpper>
      <DeleteProductDialog
        isOpen={isShowDeleteDialog}
        data={deletedData}
        onCancel={handleDeleteDialogCancel}
        onConfirm={handleDelete}
      />
    </Grid>
  );
}

const titleWrapperStyle = css`
  padding-bottom: 10px;
`;

const searchFilterStyle = css`
  color: ${FONT_COLOR.ONE};
  padding-left: 24px;
`;

const actionWrapperStyle = css`
  padding-top: 1rem;

  button {
    margin-right: 16px;
  }
`;

const searchPaperStyle = css`
  padding: 21px 16px;
`;

const inputWrapperStyle = css`
  div.label-input-wrapper {
    width: 100%;
    min-width: 220px;
    height: 40px;
  }

  div.date-range-input-wrapper {
    width: 100%;
    min-width: 280px;
    height: 40px;
  }

  div.min-number-input-wrapper,
  div.max-number-input-wrapper {
    width: 100%;
    min-width: 95px;
    height: 40px;
  }
`;

const inputTitleStyle = css`
  min-width: 58px;
  padding-right: 16px;
  text-align-last: justify;
`;
