import { css } from "@emotion/react";
import { useMemo } from "react";
import TdComponent from "components/Table/TdComponent";
import TableInput from "components/Input/TableInput";

// 新建商品＆編輯商品 Table
export default function withEditTableContainer(WrapperComponent) {
  return ({ tableData = [], setTableData = () => {} }) => {
    const everyColumnRowlength = useMemo(() => {
      const mergeColumnArray = tableData.map((record) => record["optionOne"]);
      return Math.floor(
        tableData.length / [...new Set(mergeColumnArray)].length
      );
    }, [tableData]);

    const isHasMergeColumn = useMemo(
      () =>
        Boolean(tableData[0]?.hasOwnProperty("specifyTwoId")) &&
        [...new Set(tableData.map(({ optionTwo }) => optionTwo))].length > 1,
      [tableData]
    );

    const handleInputValueChange = (record, key, value) => {
      if (!!isNaN(value)) return;
      const finalValue =
        value.trim() === "" ? "0" : parseFloat(value).toString();

      setTableData((c) => {
        const {
          level_id_one: targer_level_id_one,
          level_id_two: targer_level_id_two,
          spec_id_one: target_spec_id_one,
          spec_id_two: target_spec_id_two,
        } = record;

        const newValue = c.map(
          ({
            level_id_one,
            level_id_two,
            spec_id_one,
            spec_id_two,
            ...rest
          }) => ({
            ...rest,
            ...(Boolean(level_id_one) ? { level_id_one } : {}),
            ...(Boolean(level_id_two) ? { level_id_two } : {}),
            ...(Boolean(spec_id_one) ? { spec_id_one } : {}),
            ...(Boolean(level_id_two) ? { spec_id_two } : {}),
            ...(level_id_one === targer_level_id_one &&
            level_id_two === targer_level_id_two &&
            spec_id_one === target_spec_id_one &&
            spec_id_two === target_spec_id_two
              ? { [key]: finalValue }
              : {}),
          })
        );

        return newValue;
      });
    };

    const headerConfig = useMemo(
      () => [
        {
          key: "order",
          label: "項",
          cusStyle: css`
            min-width: 50px;
          `,
        },
        ...(Boolean(tableData[0]?.hasOwnProperty("level_name_one"))
          ? [
              {
                key: "specifyOne",
                label: tableData[0].level_name_one,
                cusStyle: css`
                  min-width: 300px;
                `,
              },
            ]
          : []),
        ...(Boolean(tableData[0]?.hasOwnProperty("level_name_two"))
          ? [
              {
                key: "spcifyTwo",
                label: tableData[0].level_name_two,
                cusStyle: css`
                  min-width: 300px;
                `,
              },
            ]
          : []),
        {
          key: "price",
          label: "售價",
          cusStyle: css`
            min-width: 130px;
          `,
        },
        {
          key: "safe_stock_qty",
          label: "安全庫存",
          cusStyle: css`
            min-width: 130px;
          `,
        },
        {
          key: "stock_qty",
          label: "商品數量",
          cusStyle: css`
            min-width: 130px;
          `,
        },
      ],
      [tableData]
    );

    const bodyConfig = [
      {
        bodyComp: (_, { tdIndex, rowIndex }) => (
          <td key={`td-${tdIndex}`} style={{ textAlign: "center" }}>
            {rowIndex + 1}
          </td>
        ),
      },
      {
        bodyComp: (record, { tdIndex, rowIndex }) => {
          // Merge Column 只需顯示最上方欄位並設定 rowSpan
          const curRowInedx = rowIndex + 1;
          const isFirstRow = curRowInedx % everyColumnRowlength === 1;
          return isHasMergeColumn && isFirstRow ? (
            <TdComponent
              key={`td-${rowIndex}-${tdIndex}`}
              rowSpan={everyColumnRowlength}
            >
              {record.spec_name_one}
            </TdComponent>
          ) : !isHasMergeColumn && record.spec_name_one !== undefined ? (
            <TdComponent key={`td-${rowIndex}-${tdIndex}`}>
              {record.spec_name_one}
            </TdComponent>
          ) : null;
        },
      },
      {
        bodyComp: (record, { tdIndex, rowIndex }) =>
          record?.hasOwnProperty("spec_name_two") ? (
            <TdComponent key={`td-${rowIndex}-${tdIndex}`}>
              {record.spec_name_two}
            </TdComponent>
          ) : null,
      },
      {
        bodyComp: (record, { rowIndex, tdIndex }) => (
          <TableInput
            key={`td-${rowIndex}-${tdIndex}`}
            value={record.price}
            onChange={(value) => {
              handleInputValueChange(record, "price", value);
            }}
          />
        ),
      },
      {
        bodyComp: (record, { rowIndex, tdIndex }) => (
          <TableInput
            key={`td-${rowIndex}-${tdIndex}`}
            value={record.safe_stock_qty}
            onChange={(value) => {
              handleInputValueChange(record, "safe_stock_qty", value);
            }}
          />
        ),
      },
      {
        bodyComp: (record, { rowIndex, tdIndex }) => (
          <TableInput
            key={`td-${rowIndex}-${tdIndex}`}
            value={record.stock_qty}
            onChange={(value) => {
              handleInputValueChange(record, "stock_qty", value);
            }}
          />
        ),
      },
    ];

    return (
      <WrapperComponent
        headerConfig={headerConfig}
        bodyConfig={bodyConfig}
        data={tableData}
      />
    );
  };
}
