import { css } from "@emotion/react";
import Pagination from "components/Common/Pagination";
import TableTitleText from "components/Typography/TableTitleText";
import DragRow from "./DragRow";
import { useCallback } from "react";

export default function DragTable({
  data = [],
  headerConfig = [],
  bodyConfig = [],
  enableHover = true,
  isShowPagination = false,
  minWidth,
  totalPage,
  perPage,
  page,
  onPerPageChange = () => {},
  onPageChange = () => {},
  setTableData = () => {},
}) {
  const moveRow = useCallback((fromIndex, toIndex) => {
    setTableData((c) => {
      const updatedRows = [...c];
      const [movedRow] = updatedRows.splice(fromIndex, 1);
      updatedRows.splice(toIndex, 0, movedRow);
      return updatedRows;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <table css={css(tableStyle)}>
        <thead>
          <tr css={css(headerStyle)}>
            {headerConfig.map(({ key, label, cusStyle }) => (
              <td key={key} css={cusStyle}>
                <TableTitleText cusStyle={cusStyle}>{label}</TableTitleText>
              </td>
            ))}
          </tr>
        </thead>
        <tbody css={css(tbodyStyle(enableHover))}>
          {data.map((record, index) => (
            <DragRow
              key={`tr-${index}`}
              index={index}
              record={record}
              moveRow={moveRow}
            >
              {bodyConfig.map(({ bodyComp = () => {} }, tdIndex) =>
                bodyComp(record, {
                  rowIndex: index,
                  tdIndex,
                })
              )}
            </DragRow>
          ))}
        </tbody>
      </table>
      {data.length === 0 && <div css={css(emptyDivStyle)}>查無紀錄</div>}
      {isShowPagination && (
        <Pagination
          minWidth={minWidth}
          totalPage={totalPage}
          perPage={perPage}
          page={page}
          onPerPageChange={onPerPageChange}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
}

const headerStyle = css`
  background: #0000000f;
  height: 38px;
`;

const tableStyle = css`
  overflow: auto;
  td {
    border: 1px solid #b9b9b9;
  }
  border-collapse: separate;
  width: 100%;
  border-spacing: 0;
  border: 1px solid #b9b9b9;
`;

const tbodyStyle = (enableHover) => css`
  td {
    height: 54px;
    padding: 0;
  }
  ${enableHover &&
  `& tr:hover {
    background: #F9F9F9;
  }`}
`;

const emptyDivStyle = css`
  padding: 1rem;
  text-align: center;
  font-size: 1.2rem;
`;
