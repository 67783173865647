import BasicButton from "./BasicButton";
import SwapVertIcon from '@mui/icons-material/SwapVert';

export default function SortButton({
  cusStyle,
  onClick = () => {},
  variant = "contained",
  label = "調整排序",
}) {
  return (
    <BasicButton
      onClick={onClick}
      cusStyle={cusStyle}
      startIcon={<SwapVertIcon />}
      variant={variant}
      label={label}
    />
  );
}
