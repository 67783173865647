import Paper from "components/Common/Paper";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useEffect, useMemo, useState } from "react";
import NavigateBar from "components/Layout/NavigateBar";
import ContentWrpper from "components/Layout/ContentWrpper";
import { useDispatch } from "react-redux";
import { setErrorToast, setSuccessToast } from "feature/redux/slice/toast";
import withCategoryTableContainer from "components/Table/Hoc/withCategoryTableContainer";
import Table from "components/Table/Table";
import { css } from "@emotion/react";
import Title from "components/Typography/Title";
import FieldInput from "components/Input/FieldInput";
import AddButton from "components/Button/AddButton";
import { flexHorizontalCenter, flexHorizontalSpaceBetween } from "style/flex";
import {
  categorySortApi,
  createAndUpdateCategoryApi,
  deleteCategoryApi,
  getCategoryListApi,
} from "api/category";
import DeleteCategoryDialog from "components/Dialog/DeleteCategoryDialog";
import SortButton from "components/Button/SortButton";
import DragTable from "components/Table/DragTable";
import CancelButton from "components/Button/CancelButton";
import SaveButton from "components/Button/SaveButton";

const DraggableTable = withCategoryTableContainer(DragTable);
const FunctionTable = withCategoryTableContainer(Table);

export default function ProductCategory() {
  const dispatch = useDispatch();
  const [categoryName, setCategoryName] = useState("");
  const [tableData, setTableData] = useState([]);
  const [dragBeforData, setDragBeforData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [deletedData, setDeleteData] = useState({});
  const [totalRecord, setTotalRecord] = useState(0);
  const [isShowDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDrag, setDrag] = useState(false);
  const Table = isDrag ? DraggableTable : FunctionTable;
  const isEditting = useMemo(
    () => tableData.some(({ isEditting }) => Boolean(isEditting)),
    [tableData]
  );

  // 編輯按鈕
  const handleEditButtonClick = ({ category_id }) => {
    setTableData((c) =>
      c.map((data) =>
        category_id === data.category_id ? { ...data, isEditting: true } : data
      )
    );
  };

  // 刪除按鈕
  const handleDeleteButtonClick = (row) => {
    setShowDeleteDialog(true);
    setDeleteData(row);
  };

  // 取消按鈕
  const handleInputCancel = (id) => {
    const { category_name: originName } = originalData.filter(
      ({ category_id }) => category_id === id
    )[0];

    setTableData((c) =>
      c.map((data) =>
        id === data.category_id
          ? { ...data, category_name: originName, isEditting: false }
          : data
      )
    );
  };

  const handleInputValueChange = (id, name) => {
    setTableData((c) =>
      c.map((data) =>
        id === data.category_id ? { ...data, category_name: name } : data
      )
    );
  };

  // 編輯名稱儲存按鈕
  const handleInputSave = async (id) => {
    try {
      const target = tableData.filter(
        ({ category_id }) => category_id === id
      )[0];

      const { category_name, parent_id, sort_order } = target;
      if (category_name.trim().length > 0) {
        await createAndUpdateCategoryApi({
          action: "update",
          category_id: id,
          category_info: {
            category_name,
            parent_id,
            sort_order,
          },
        });

        setOriginalData((c) =>
          c.map(({ category_id, category_name: originName, ...rest }) =>
            category_id === id
              ? { ...rest, category_name, category_id }
              : { category_id, category_name: originName, ...rest }
          )
        );
        setTableData((c) =>
          c.map((data) =>
            id === data.category_id ? { ...data, isEditting: false } : data
          )
        );
        dispatch(setSuccessToast("儲存成功"));
      } else dispatch(setErrorToast("請輸入分類名稱"));
    } catch (error) {
      dispatch(setErrorToast(error?.message || "儲存失敗"));
    }
  };

  const handleDeleteDialogCancel = () => {
    setShowDeleteDialog(false);
    setDeleteData({});
  };

  const handleDelete = async (data = {}) => {
    try {
      const { category_id } = data;
      await deleteCategoryApi(category_id);

      setShowDeleteDialog(false);
      dispatch(setSuccessToast("刪除成功"));
      getData();
      setDeleteData({});
    } catch (error) {
      dispatch(setErrorToast(error?.message || "刪除失敗"));
    }
  };

  const handleSave = async () => {
    try {
      if (categoryName.trim().length > 0) {
        await createAndUpdateCategoryApi({
          action: "new",
          category_info: {
            category_name: categoryName,
          },
        });

        setCategoryName("");
        dispatch(setSuccessToast("新增成功"));
        getData();
      }
    } catch (error) {
      dispatch(setErrorToast(error?.message || "新增失敗"));
    }
  };

  const handleEnableSort = () => {
    if (isEditting) {
      dispatch(setErrorToast("分類名稱尚未儲存"));
    } else {
      setDragBeforData(tableData);
      setDrag((c) => !c);
    }
  };

  const handleDragCancel = () => {
    setTableData(dragBeforData);
    setDrag((c) => !c);
  };

  const getData = async () => {
    try {
      const resp = await getCategoryListApi();
      const { list = [], page_info: { total_records } = {} } = resp;

      setTotalRecord(total_records);
      setTableData(list.map((data) => ({ isEditting: false, ...data })));
      setOriginalData(list);
    } catch (error) {
      dispatch(setErrorToast(error?.message || "取得資料失敗"));
    }
  };

  const handleDragSave = async () => {
    try {
      const categoryies = tableData.map(({ category_id }) => ({ category_id }));
      await categorySortApi(categoryies);
      setDragBeforData(tableData);
      dispatch(setSuccessToast("儲存成功"));
      setDrag(false);
    } catch (error) {
      setErrorToast(error?.message || "儲存失敗");
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid xs={12} sx={{ padding: "0" }}>
        <NavigateBar></NavigateBar>
      </Grid>
      <ContentWrpper>
        <Grid xs={12}>
          <Paper>
            <Grid container>
              <Grid xs={12} display={"flex"} css={css(inputWrapperStyle)}>
                <Title cusStyle={titleStyle}>分類名稱</Title>
                <FieldInput
                  onChange={(e) => setCategoryName(e.target.value)}
                  value={categoryName}
                  placeholder={"輸入商品分類名稱"}
                  maxLength={200}
                  inputLength={categoryName?.length || 0}
                />
              </Grid>
            </Grid>
            <Grid
              xs={12}
              css={css([flexHorizontalCenter])}
              sx={{ paddingTop: "16px" }}
            >
              <AddButton onClick={handleSave} label={"新增"} />
            </Grid>
          </Paper>
        </Grid>
        <Grid xs={12} sx={{ paddingTop: "30px" }}>
          <Paper>
            <div css={css([flexHorizontalSpaceBetween, titleWrapperStyle])}>
              <Title>商品分類總數：{totalRecord}</Title>
              {isDrag ? (
                <div css={css(buttonWrapperStyle)}>
                  <CancelButton onClick={handleDragCancel} />
                  <SaveButton onClick={handleDragSave} variant="contained" />
                </div>
              ) : (
                <SortButton onClick={handleEnableSort} />
              )}
            </div>
            <Table
              isDrag={isDrag}
              setTableData={setTableData}
              tableData={tableData}
              onDeleteButtonClick={handleDeleteButtonClick}
              onEditButtonClick={handleEditButtonClick}
              onInputValueChange={handleInputValueChange}
              onInputSave={handleInputSave}
              onInputCancel={handleInputCancel}
            />
          </Paper>
        </Grid>
      </ContentWrpper>
      <DeleteCategoryDialog
        isOpen={isShowDeleteDialog}
        data={deletedData}
        onCancel={handleDeleteDialogCancel}
        onConfirm={handleDelete}
      />
    </Grid>
  );
}

const buttonWrapperStyle = css`
  button:first-of-type {
    margin-right: 16px;
  }
`;

const titleStyle = css`
  min-width: 58px;
  padding-right: 16px;
  margin-top: 12px;
  text-align: end;
`;

const inputWrapperStyle = css`
  .field-input-wrapper {
    min-width: 300px;
  }
`;

const titleWrapperStyle = css`
  padding-bottom: 10px;
`;
