import { css } from "@emotion/react";

export const fullWidthStyle = css`
  flex-grow: 1;
`;

export const paddingTop = (v) =>
  css`
    padding-top: ${v}px;
  `;

export const errorColorStyle = css`
  color: #f43737;
`;
