import { css } from "@emotion/react";
import { useEffect, useMemo, useState } from "react";
import TdComponent from "components/Table/TdComponent";
import EditButton from "components/Button/EditButton";
import DeleteButton from "components/Button/DeleteButton";
import { errorColorStyle } from "style/common";

// 我的商品 Table
export default function withMyProductTableContainer(WrapperComponent) {
  return ({
    tableData = [],
    totalPage,
    perPage,
    page,
    onPerPageChange = () => {},
    onPageChange = () => {},
    onDeleteButtonClick = () => {},
    onEditButtonClick = () => {},
  }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
      const newArray = [];
      tableData.forEach(
        ({ product_id, product_name, category_name, spec_list = [] }) => {
          spec_list.forEach((spec) => {
            const item = {
              category_name,
              product_id,
              product_name,
              ...spec,
            };
            newArray.push(item);
          });
        }
      );
      setData(newArray);
    }, [tableData]);

    // 取得每個商品的列述 Ex. { 商品id : 商品種類數量 }
    const productIdCounts = useMemo(
      () =>
        data
          .map(({ product_id }) => product_id)
          .reduce((accumulator, currentItem) => {
            // 如果當前商品名稱已經存在於累加器中，則將其數量加1，否則設置數量為1
            accumulator[currentItem] = (accumulator[currentItem] || 0) + 1;
            return accumulator;
          }, {}),
      [data]
    );

    const headerConfig = [
      {
        key: "category_name",
        label: "商品分類",
        cusStyle: css`
          min-width: 100px;
        `,
      },
      {
        key: "product_name",
        label: "商品名稱",
        cusStyle: css`
          min-width: 288px;
        `,
      },
      {
        key: "spec",
        label: "商品規格",
        cusStyle: css`
          min-width: 180px;
        `,
      },
      {
        key: "price",
        label: "售價",
        cusStyle: css`
          min-width: 100px;
        `,
      },
      {
        key: "safe_stock_qty",
        label: "安全庫存",
        cusStyle: css`
          min-width: 100px;
        `,
      },
      {
        key: "stock_qty",
        label: "現有庫存",
        cusStyle: css`
          min-width: 100px;
        `,
      },
      {
        key: "action",
        label: "操作",
        cusStyle: css`
          min-width: 200px;
        `,
      },
    ];

    const mergeColumn = ({
      record = {},
      rowIndex,
      tdIndex,
      Comp,
      key,
      isAction,
    }) => {
      const { product_id } = record;
      const prevRecord = rowIndex === 0 ? {} : data[rowIndex - 1];
      const mergetCounts = productIdCounts[product_id];
      const isFirstRow =
        rowIndex === 0 ? true : prevRecord.product_id !== product_id;

      return mergetCounts > 1 && isFirstRow ? (
        <TdComponent
          key={`td-${rowIndex}-${tdIndex}`}
          rowSpan={mergetCounts}
          align="center"
          {...(isAction ? { cusStyle: actionStyle } : {})}
        >
          {Comp || record[key]}
        </TdComponent>
      ) : mergetCounts > 1 && !isFirstRow ? null : (
        <TdComponent
          key={`td-${rowIndex}-${tdIndex}`}
          align="center"
          {...(isAction ? { cusStyle: actionStyle } : {})}
        >
          {Comp || record[key]}
        </TdComponent>
      );
    };

    const bodyConfig = [
      {
        bodyComp: (record, { rowIndex, tdIndex }) =>
          mergeColumn({
            record,
            rowIndex,
            tdIndex,
            key: "category_name",
          }),
      },
      {
        bodyComp: (record, { rowIndex, tdIndex }) =>
          mergeColumn({
            record,
            rowIndex,
            tdIndex,
            key: "product_name",
          }),
      },
      {
        bodyComp: ({ spec_name }, { rowIndex, tdIndex }) => (
          <TdComponent key={`td-${rowIndex}-${tdIndex}`}>
            {spec_name === "" ? "單一規格" : spec_name}
          </TdComponent>
        ),
      },
      {
        bodyComp: ({ price, is_low_stock }, { rowIndex, tdIndex }) => (
          <TdComponent key={`td-${rowIndex}-${tdIndex}`} align="right">
            {Number(price).toLocaleString()}
            <br />
            {is_low_stock && <span css={css(errorColorStyle)}>低庫存</span>}
          </TdComponent>
        ),
      },
      {
        bodyComp: ({ safe_stock_qty }, { rowIndex, tdIndex }) => (
          <TdComponent key={`td-${rowIndex}-${tdIndex}`} align="right">
            {Number(safe_stock_qty).toLocaleString()}
          </TdComponent>
        ),
      },
      {
        bodyComp: ({ stock_qty }, { rowIndex, tdIndex }) => (
          <TdComponent key={`td-${rowIndex}-${tdIndex}`} align="right">
            {Number(stock_qty).toLocaleString()}
          </TdComponent>
        ),
      },
      {
        bodyComp: (record = {}, { rowIndex, tdIndex }) =>
          mergeColumn({
            isAction: true,
            record,
            rowIndex,
            tdIndex,
            Comp: (
              <>
                <EditButton
                  onClick={() => {
                    onEditButtonClick(record);
                  }}
                />
                <DeleteButton
                  label="刪除"
                  onClick={() => {
                    onDeleteButtonClick(record);
                  }}
                />
              </>
            ),
          }),
      },
    ];

    return (
      <>
        <WrapperComponent
          headerConfig={headerConfig}
          bodyConfig={bodyConfig}
          data={data}
          minWidth={1080}
          isShowPagination
          totalPage={totalPage}
          perPage={perPage}
          page={page}
          onPerPageChange={onPerPageChange}
          onPageChange={onPageChange}
        />
      </>
    );
  };
}

const actionStyle = css`
  & button:first-of-type {
    margin-right: 4px;
  }

  & button:last-child {
    margin-left: 4px;
  }
`;
