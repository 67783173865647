import { useEffect, useState } from "react";

export default function useGenerateTableData(specLevel) {
  const [tableData, setTableData] = useState([
    {
      price: 0,
      stock_qty: 0,
    },
  ]);

  useEffect(() => {
    setTableData((c) => {
      const levelCount = specLevel.length;

      if (levelCount === 0) {
        // 沒有規格
        return [
          {
            price: c[0].price,
            stock_qty: c[0].stock_qty,
            safe_stock_qty: c[0].safe_stock_qty,
          },
        ];
      } else if (levelCount === 1) {
        // 只有一組規格
        const data = [];
        const { level_id, level_name, spec_options = [] } = specLevel[0];

        if (spec_options.length === 1) {
          return [
            {
              level_id_one: level_id,
              level_name_one: level_name,
              spec_name_one: spec_options[0].spec_name,
              spec_id_one: spec_options[0].spec_id,
              price: c[0].price,
              stock_qty: c[0].stock_qty,
              safe_stock_qty: c[0].safe_stock_qty,
            },
          ];
        } else {
          spec_options.forEach(({ spec_name, spec_id }) => {
            const prev = c.filter(({ spec_id_one }) => spec_id_one === spec_id);

            data.push({
              level_id_one: level_id,
              level_name_one: level_name,
              spec_name_one: spec_name,
              spec_id_one: spec_id,
              price: prev.length === 1 ? prev[0].price : 0,
              stock_qty: prev.length === 1 ? prev[0].stock_qty : 0,
              safe_stock_qty: prev.length === 1 ? prev[0].safe_stock_qty : 0,
            });
          });

          return data;
        }
      } else {
        // 有兩組規格
        const data = [];
        const {
          level_name: level_name_one,
          level_id: level_id_one,
          spec_options: spec_options_one = [],
        } = specLevel[0];
        const {
          level_name: level_name_two,
          level_id: level_id_two,
          spec_options: spec_options_two = [],
        } = specLevel[1];

        spec_options_one.forEach(
          ({ spec_name: spec_name_one, spec_id: spec_id_one }) => {
            spec_options_two.forEach(
              ({ spec_name: spec_name_two, spec_id: spec_id_two }) => {
                const prev = c.filter(
                  ({
                    spec_id_one: prew_spec_id_one,
                    spec_id_two: prev_spec_id_two,
                  }) =>
                    prew_spec_id_one === spec_id_one &&
                    prev_spec_id_two === spec_id_two
                );

                data.push({
                  level_id_one,
                  level_name_one,
                  spec_name_one,
                  spec_id_one,
                  level_id_two,
                  level_name_two,
                  spec_name_two,
                  spec_id_two,
                  price: prev.length === 1 ? prev[0].price : 0,
                  stock_qty: prev.length === 1 ? prev[0].stock_qty : 0,
                  safe_stock_qty:
                    prev.length === 1 ? prev[0].safe_stock_qty : 0,
                });
              }
            );
          }
        );
        return data;
      }
    });
  }, [specLevel]);

  return { tableData, setTableData };
}
